import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import { Gift } from '../components/gift'
import '../style/gold3.css'
import '../style/style.css'
import '../style/sty.scss'
import logoig from '../assets/img/nasta/logoig.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import getData from '../params/getdata'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#B99225'
let black = 'rgb(38,38,38)'

let id = 'yumna-rudi'
let inisial_co = 'Rudi'
let inisial_ce = 'Yumna'

let lengkap_co = (<>Rudiansyah, S.S., M. Hum</>)
let lengkap_ce = (<>Siti Yumna Annisa, S.Tr.Par </>)

let bapak_co = 'Bpk. Torik Siswanto '
let ibu_co = 'Ibu Poniarti'
let bapak_ce = "Bpk. Surianto (alm) "
let ibu_ce = "Ibu Ayun Mawiti, A.Md.Kep"

let ig_co = "rudikoencoro"
let ig_ce = "syaaadz"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "01/02/2021"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://goo.gl/maps/KXuYJbvWkUjVMrDLA"
let gcalendar = "https://calendar.google.com/event?action=TEMPLATE&tmeid=MmJ0dTFrYTRhbGxjcGhra29rcThydm12dG0gYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com"
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.2431755453676!2d99.96809631475503!3d2.0586629984992673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMsKwMDMnMzEuMiJOIDk5wrA1OCcxMy4wIkU!5e0!3m2!1sen!2sid!4v1607950978200!5m2!1sen!2sid"


export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2,
            comment: [],
            showgift:false
        }
    }

    async componentDidMount() {
        try {
            AOS.init({
                // initialise with other settings
                duration: 2000
            });
            let comment = await getData('kepada:"yumna-rudi"')

            this.setState({ comment: comment })
        } catch (error) {
            console.log(error)
        }

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, showgift, hide, submitted, err, comment } = this.state
        let slider = []
        for (let index = 31; index < 41; index++) {
            slider[index] = gambar(pw(id, index + "a.jpg"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=800&h=525')
        }
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''
        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5' style={{
                    backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Buka Undangan
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>

                                        <p className="fs16 text-center cblack px-3">
                                            “Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu istri-istri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.” <br /><br />(Ar-Rum: 21)

                                        </p>
                                    </Item>

                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={pw("asset", "burung-gold.svg")} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>


                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            Pernikahan <br /> {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                </Container>
                                <Container className="pt-3" >
                                    <Item>
                                        <Col xs={10} md={4}>
                                            <img src={pw("arin-irwan", "bismillah-black.svg")} className="img-fluid w-100" />
                                        </Col>
                                        <p className="fs16 text-center  px-3 py-3 w-100 text-center"
                                            style={{ color: '#171717', fontSize: '16px' }} data-aos="fade-left">
                                            Assalaamu'alaikum Warahmatullahi  Wabarakatuh<br /><br />
                                            Dengan memohon rahmat dan ridha Allah SWT,<br />
                                            kami bermaksud menyelenggarakan pernikahan putra-putri kami
                                        </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{ fontSize: '24px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putri dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={pw("asset", "logoig-gold.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_co, 91)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '24px',
                                                        fontFamily: "'Marck Script', cursive", color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putra dari:</b><br />
                                                        {bapak_co}
                                                        <br />
                                                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={pw("asset", "logoig-gold.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: black }} >
                                    <Item>
                                        <p className="fs16">
                                            Yang Insyaa Allah akan dilaksanakan pada:
                    </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20">
                                            <b>
                                                SABTU <span className="fs36">02</span> JANUARI 2021
                      </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20 col-sm-4" style={{ color: cmain }}>
                                            <b>Akad Nikah </b><br />
                                            <span className="cblack fs16">

                                                08.00 WIB - Selesai
                      </span>
                                            {/* <br />
                      <span className="cblack fs12">
                        (Hanya dihadiri oleh keluarga)
                      </span> */}

                                        </p>
                                        <p className="px-3 d-none d-sm-block" style={{ color: cmain, fontSize: '72px' }}>
                                            \
                    </p>
                                        <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: cmain }}>
                                            <b>Resepsi</b><br />

                                            <span className="cblack fs16">
                                                10.00 WIB - Selesai

                                               </span>


                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs16 pt-3">
                                            <b>Komp.perumahan perbaungan baru, Aek Nabara

                                            <br />
                                            Kec. Bilah Hulu, Kab. Labuhan batu
                                            Sumatera Utara, 21451
                                            </b>
                                        </p>
                                    </Item>

                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                            </iframe></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />

                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={pw("asset", "covid-gold.png")} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14'>
                                                            Jika allah berkehendak menyatukan dua hati, maka keduanya pasti akan bersatu walaupun diantara keduanya terbentang langit dan bumi</p>
                                                    </Item>
                                                </div>
                                            </Item>
                                        </div>
                                    </div>
                                </Container>
                                <Container className="py-3" fluid >
                                    <Item>
                                        <Col
                                            onClick={() => this.setState({ showgift: !showgift })}
                                            xs={10} md={4}
                                            style={{
                                                border: `2px solid #86835dff`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-md-2 mt-3 mt-md-0">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: '#86835dff' }}>
                                                    <b>Send Gift (Klik Disini)</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                                {
                                    showgift ? (
                                        <Gift
                                            reza
                                            yumna
                                            content={
                                                [
                                                    {
                                                        bank: 'BNI',
                                                        norek: '0437071996 ',
                                                        nama: 'Siti Yumna Annisa'
                                                    },

                                                ]
                                            }
                                            caption='For those who want to give gifts to our wedding, kindly transfer to the following accounts :'
                                            ccaption="black"
                                            color={'black'}
                                            cmain={cmain}
                                            langsung
                                            alamat="Jln perintis baru perumahan green harmony no A4, Kec. Sunggal, Kab. deli serdang, Sumatera Utara 20351"
                                        />
                                    ):false
                                }
                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Kirim Ucapan
                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Nama" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Ucapan" name='pesan' />
                                                        <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Hadir</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Tidak Hadir</label>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Item>

                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Container className="py-5 px-md-5 col-md-8 px-3"
                                    style={{ color: cmain, fontFamily: 'Patrick Hand, cursive' }}
                                >

                                    <Row className="position-relative px-5 py-5" style={{ backgroundColor: 'rgba(255,255,255,0.1)' }}>
                                        <h1 className="peri w-100 text-center">
                                            Ucapan dan doa
                                        </h1>
                                        <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', top: 0 }}>
                                            <img src={gambar(pw("asset", "ornament-gold.png"), 95)} className="img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', top: 0 }}>
                                            <img src={gambar(pw("asset", "ornament-gold.png"), 95)} className="mirror img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', bottom: 0 }}>
                                            <img src={gambar(pw("asset", "ornament-gold.png"), 95)} className="flip180-m img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', bottom: 0 }}>
                                            <img src={gambar(pw("asset", "ornament-gold.png"), 95)} className=" flip180  img-fluid w-100" />
                                        </Col>
                                        <Row style={{ maxHeight: '50vh', overflowY: 'scroll', width: '100%' }} className="scroller">
                                            {comment.map((v, i) => {
                                                return (
                                                    <Row className="w-100 text-center mx-auto">
                                                        <Col></Col>
                                                        <Col xs={12} md={6} className="py-2">
                                                            <p className="w-100 text-center my-auto" style={{ fontSize: '18px' }}>
                                                                " {decodeURI(v.pesan)} " <br /> - {decodeURI(v.dari)} -
                                                            </p>
                                                        </Col>
                                                        <Col></Col>
                                                    </Row>
                                                )
                                            })
                                            }
                                        </Row>

                                    </Row>

                                </Container>

                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

